import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React, { useContext } from "react";
import { Button } from "@mui/material";
import "./RegistrationSuccessModal.css";
import theme from "../../utils/theme";
import { useHistory } from "react-router-dom";

const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const RegistrationSuccessModal = (props) => {

  const history = useHistory();


  const handleContinue = async () => {
    props.setToggle(false);
    history.push("/sign-up-otp");
  };

  return (
    <div>
      <Modal
        open={props.Toggle}
       
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={style}
      >
        <Box className="modal-body">
          <img src="/images/Sucess.png" alt="Success" />
          <Typography
            style={{ color: theme.palette.secondary.main }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Success
          </Typography>
          <Typography
            id="modal-modal-description"
            style={{ textAlign: "center" }}
          >
            Congratulations, your account has been successfully created! Start
            exploring local businesses today.
          </Typography>
          <Button
            className="btn-reg-success"
            variant="contained"
            style={{ backgroundColor: theme.palette.secondary.main }}
            onClick={handleContinue}
          >
            Continue
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default RegistrationSuccessModal;
