import React, { memo } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "./NewsPaper.css";
import { useHistory } from "react-router-dom";
import { Box, Card, CardContent, Grid } from "@mui/material";
import useCommonQuery from "../../../store/common/useCommonQuery";
import ShareIcon from "@mui/icons-material/Share";

const NewsPaper = () => {
  const history = useHistory();

  const { data: newsPaperList } = useCommonQuery.Get_newsPaper({
    limit: 500,
    offset: 0,
  });

  const handleShare = async () => {
    try {
      await navigator.share({
        url: `https://masterclick.in/news-paper`,
      });
    } catch (error) { }
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
          color: "black",
          display: "flex",
          width: "100%",
          alignItems: "center",
          gap: "1rem",
          flexDirection: "row",
          padding: "1rem",
        }}
      >
        <ArrowBackIcon
          onClick={() => history.push("/")}
          style={{
            color: "black",
          }}
        />
        <p style={{ color: "black" }}>Newspaper</p>
      </div>

      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', m: '.6rem' }}>
      <div className="news-head">മലയാള ന്യൂസ് പേപ്പർകൾ</div>
        <div className="news-button-class" onClick={handleShare}>
          <ShareIcon />
        </div>
      </Box>
      <Grid container spacing={2} sx={{ padding: "0px 12px" }}>
        {newsPaperList &&
          newsPaperList.data.results.map((item, idx) => {
            return (
              <Grid item xs={4} key={idx}>
                <a href={item?.url ? item?.url : ""}>
                  <Card
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "10px",
                      height: "100px",
                      width: "100px",
                    }}
                  >
                    <CardContent
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{ width: "5rem", height: "5rem" }}
                        src={item.image}
                        alt={item.name}
                      />
                    </CardContent>
                  </Card>
                </a>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default memo(NewsPaper);
