import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addBookmark,
  addBusiness,
  addCallers,
  addViews,
  deleteBusiness,
  editBussiness,
  getBookmark,
  getBusinessDetails,
  getBusinessList,
  getCurrentPlan,
  getDetails,
  getMyBussiness,
  getRating,
  getSubcriptionPlan,
  getTrialplanDetails,
  updateRating,
} from "./bussinessUrl";

const Get_my_bussiness = () => {
  return useQuery(["businessList"], () => getMyBussiness());
};

const Get_bussiness_list = (query) => {
  return useQuery(["bussiness", query], () => getBusinessList(query), {
  });
};

const Get_subcription = (id) => {
  return useQuery(["subscription", id], () => getSubcriptionPlan(id), {
    enabled: !!id,
  });
};

const Get_my_bussiness_details = (id) => {
  return useQuery(["businessDetails"], () => getBusinessDetails(id));
};

const Get_my_currentplan = (id) => {
  return useQuery(["currentPlan"], () => getCurrentPlan(id), {
    enabled: !!id,
  });
};

const Get_details = (id) => {
  return useQuery(["getDetails"], () => getDetails(id), {
    enabled: !!id,
  })
};

const Get_bookmark_list = (userID) => {
  return useQuery(["bookmarkList"], () => getBookmark(), {
    enabled: !!userID,
  })
};

const Get_rating = (id) => {
  return useQuery(["ratingList"], () => getRating(id));
};

const Get_trial_plan = () => {
  return useQuery(["trialPlan"], () => getTrialplanDetails());
};

const Add_views = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => addViews(data), {
    onMutate: async (newData) => {
      queryClient.cancelQueries("businessList");
      const prev = await queryClient.getQueryData("businessList");
      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("businessList");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("businessList", context.prev);
    },
  });
};


const Add_calls = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => addCallers(data), {
    onMutate: async (newData) => {
      queryClient.cancelQueries("businessDetails");
      const prev = await queryClient.getQueryData("businessDetails");
      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("businessDetails");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("businessDetails", context.prev);
    },
  });
};

const Add_business = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => addBusiness(data), {
    onMutate: async (newData) => {
      queryClient.cancelQueries("businessList");
      const prev = await queryClient.getQueryData("businessList");
      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("businessList");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("businessList", context.prev);
    },
  });
};

const Edit_business = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => editBussiness(data), {
    onMutate: async (newData) => {
      queryClient.cancelQueries("businessList");
      const prev = await queryClient.getQueryData("businessList");
      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("businessList");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("businessList", context.prev);
    },
  });
};

const Del_business = () => {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteBusiness(id), {
    onMutate: async (newData) => {
      queryClient.cancelQueries("businessList");
      const prev = await queryClient.getQueryData("businessList");
      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("businessList");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("businessList", context.prev);
    },
  });
};

const Add_bookmark = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => addBookmark(data), {
    onMutate: async (newData) => {
      queryClient.cancelQueries("bookmarkList");
      const prev = await queryClient.getQueryData("bookmarkList");
      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("bookmarkList");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("bookmarkList", context.prev);
    },
  });
};

const Update_rating = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => updateRating(data), {
    onMutate: async (newData) => {
      queryClient.cancelQueries("ratingList");
      const prev = await queryClient.getQueryData("ratingList");
      return {
        prev,
        newData,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries("ratingList");
    },
    onError: (err, _, context) => {
      queryClient.setQueryData("ratingList", context.prev);
    },
  });
};

const useBusinessQuery = {
  Add_business,
  Get_my_bussiness,
  Del_business,
  Get_subcription,
  Get_my_bussiness_details,
  Edit_business,
  Get_bussiness_list,
  Get_bookmark_list,
  Add_bookmark,
  Get_my_currentplan,
  Get_rating,
  Update_rating,
  Get_details,
  Get_trial_plan,
  Add_views,
  Add_calls
};

export default useBusinessQuery;
