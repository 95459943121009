import React, { useCallback, useContext, useEffect, useState } from "react";
import "./Home.css";
import Header from "../../components/header/Header";
import HeroCarousel from "../../components/Carousel";
import CategoreyCard from "../../components/CategoreyCard";
import ProductCard from "../../components/ProductCard";
import BottomNav from "../../components/Bottom nav/BottomNav";
import { Box, CircularProgress, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import { variableContext } from "../../context/VariableContex";
import useBusinessQuery from "../../store/Bussiness/useBusinessQuery";
import useCommonQuery from "../../store/common/useCommonQuery";
import RegistrationErrorModal from "../../components/registrationModal/RegistrationErrorModal";
import axios from "axios";
import Empty from "../../assets/Empty.mp4";
import BottomIcons from "../../components/BottomIcons/Index";
import { Virtuoso } from 'react-virtuoso'

const Home = () => {
  const history = useHistory();
  const { locationData, setLocationFilter } = useContext(variableContext);

  const Api_Key = "AIzaSyDMC5uUfcSScgVY9AGqsGQwYm8P6_LmyYg";

  const [premium, setPremium] = useState([]);
  const [openLocation, setOpenLocation] = useState(false);
  const query = {
    latitude: locationData?.latitude,
    longitude: locationData?.longitude,
    radius: locationData?.radius || null,
    category: "",
    subCategory: "",
    name: "",
    limit: 2000,
    offset: 0,
  };

  const { data: categoryList } = useCommonQuery.Get_category({
    search: "",
    limit: 500,
    offset: 0,
  });

  console.log(query)

  const {
    data: businessList,
    refetch,
    isSuccess,
    isError,
    isLoading
  } = useBusinessQuery.Get_bussiness_list(query);

  const { data: getPubliceService } = useCommonQuery.Get_public_serivce({
    limit: 500,
    offset: 0,
  });

  const fetchCityName = useCallback(async (latitude, longitude, setLocationFilter) => {
    if (!latitude || !longitude) return; // Avoid unnecessary API calls
  
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${Api_Key}`
      );
      
      const results = response.data.results;
      if (results && results.length > 0) {
        const addressComponents = results[0].address_components;
        const city = addressComponents.find(component => component.types.includes("locality"));
        
        if (city) {
          setLocationFilter('locationName', city.long_name);
        }
      }
    } catch (error) {
      console.error("Error fetching city name:", error);
    }
  }, []);
  
  const handleClose = useCallback(() => {
    setOpenLocation(false); // Assuming this closes a Snackbar or UI feedback
  }, []);
  
  useEffect(() => {
    if (!locationData?.latitude || !locationData?.longitude) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          setLocationFilter('latitude', position.coords.latitude);
          setLocationFilter('longitude', position.coords.longitude);
        },
        )
      }
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, [locationData]);
  
  const [prevLocation, setPrevLocation] = useState(null);

  useEffect(() => {
    if (
      locationData?.latitude &&
      locationData?.longitude &&
      (prevLocation?.latitude !== locationData.latitude || prevLocation?.longitude !== locationData.longitude)) {
      fetchCityName(locationData.latitude, locationData.longitude, setLocationFilter);
      setPrevLocation({ latitude: locationData.latitude, longitude: locationData.longitude });
    }
  
    if (locationData?.latitude || locationData?.longitude || locationData?.radius) {
      refetch();
    }
  }, [locationData, prevLocation]);
  

  useEffect(() => {
    if (isSuccess && businessList) {
      const premiumList = businessList?.data?.filter((item) => item.is_premium);
      setPremium([...premiumList]);
    }
    if (isSuccess || businessList || isError) {
      if (businessList) {
        if (businessList?.data?.length === 0) {
          const newClass = document.getElementById("div");
          newClass.classList.toggle("hide", true);
          // const noDatas = document.getElementById("no-datas");
          // noDatas.classList.toggle("hide", false);
        } else {
          const newClass = document.getElementById("div");
          newClass.classList.toggle("hide", false);
          // const noDatas = document.getElementById("no-datas");
          // noDatas.classList.toggle("hide", true);
        }
      } else {
        const newClass = document.getElementById("div");
        newClass.classList.toggle("hide", true);
        // const noDatas = document.getElementById("no-datas");
        // noDatas.classList.toggle("hide", false);
      }
    }
  }, [isSuccess, businessList, isError]);

  const handleClick = (id) => {
    history.push(`/subcategory/${id}`);
  };
  return (
    <div>
      <Header />
      <div
        className=""
        id="div"
        style={{ marginBottom: premium.length > 0 ? "0px" : "7rem" }}
      >
        <Box sx={{ marginTop: "150px", padding: "0px 17px" }}>
          <HeroCarousel data={premium} />
        </Box>
      </div>
      <div
        style={{
          width: "93%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: ".3rem",
          margin: ".8rem",
          position: premium.length > 0 ? "static" : "absolute",
          top: "20%",
          zIndex: "1",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <div
              style={{
                backgroundColor: "#008ABB",
                boxShadow: "3px 3px 5px rgb(0 138 187 / 44%)",
              }}
              className="public-div"
              onClick={() =>
                history.push(`/publicservice/${getPubliceService?.message?.id}`)
              }
            >
              Public Service Centres
            </div>
          </Grid>
          <Grid item xs={6}>
            <div
              onClick={() => history.push("/service-link")}
              style={{
                backgroundColor: "rgb(122 54 39)",
                boxShadow: "3px 3px 5px #a52a2a8c",
              }}
              className="public-div"
            >
              Valuable Web Links
            </div>
          </Grid>
          <Grid item xs={6}>
            <div
              onClick={() => history.push("/news-paper")}
              style={{
                backgroundColor: "#2C887D",
                boxShadow: "3px 3px 5px rgb(44 136 125 / 50%)",
              }}
              className="public-div"
            >
              Malayalam News Paper
            </div>
          </Grid>
          <Grid item xs={6}>
            <div
              onClick={() => history.push("/news-channel")}
              style={{
                backgroundColor: "#7A6327",
                boxShadow: "3px 3px 5px rgb(122 99 39 / 56%)",
              }}
              className="public-div"
            >
              Malayalam News Channel
            </div>
          </Grid>
        </Grid>
      </div>
      {isLoading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 5 }}><CircularProgress /></Box> : ''}
      {premium?.length > 0 ? (
        <div style={{ margin: "1rem" }}>
          <h4>Trending Categories in your area</h4>
          <Grid container columnSpacing={1} rowSpacing={2}>
            {categoryList &&
              categoryList?.data?.results?.map((items, idx) => {
                if (items.is_trending) {
                  return (
                    <CategoreyCard
                      id={items.id}
                      title={items.name}
                      image={items.image}
                      handleClick={handleClick}
                      key={idx}
                    />
                  );
                }
              })}
          </Grid>
        </div>
      ) : null}

      {premium && premium?.length > 0 ? (
        
        <div style={{ margin: "1rem", paddingBottom: "10px" }}>
          <h4>Premium Listings in your area </h4>
          {premium &&
            <Virtuoso
              style={{ height: 500 }}
              data={premium}
              itemContent={(_, item) => (
                <ProductCard item={item} key={item.id} />
              )}
            />
          }
        </div>
      ) : null}
      <div>
        {premium?.length === 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              position: "absolute",
              bottom: "10vh",
            }}
          >
            <video autoPlay muted width={"100%"} height={"100%"} loop>
              <source src={Empty} type="video/mp4" />
            </video>
          </div>
        ) : null}

        <Box
          sx={{
            background: "#2182B1",
            paddingBottom: premium.length > 0 ? "80px" : "30px",
            paddingTop: "5px",
            width: "100%",
            position: premium.length > 0 ? "static" : "absolute",
            bottom: "45px",
          }}
        >
          <BottomIcons />
        </Box>
      </div>

      <BottomNav />
      {openLocation ? (
        <RegistrationErrorModal
          Toggle={openLocation}
          handleClose={handleClose}
          message="Please provide location to proceed"
          title=""
        />
      ) : null}
    </div>
  );
};

export default React.memo(Home);
