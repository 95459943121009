import React, { memo, useEffect, useState } from "react";
import "./ProductDetails.css";
import theme from "../../utils/theme";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useBusinessQuery from "../../store/Bussiness/useBusinessQuery";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import noPic from "../../assets/no-pic.png";
import locations from "../../assets/locations.svg";
import phone from "../../assets/phone.svg";
import thumbnail from "../../assets/thumbnail.png";
import categoryicon from "../../assets/categoryicon.png";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import web from "../../assets/web.svg";
import whatsapp from "../../assets/whatsapp.svg";
import Youtube from "../../assets/Youtube.svg";
import instagram from "../../assets/Instagram.svg";
import facebook from "../../assets/facebook.svg";
import ShareIcon from "@mui/icons-material/Share";
import google from "../../assets/google.svg";
import phonecall from "../../assets/phonecall.svg";
import HeroCarousel from "../../components/Carousel";

const ProductDetails = () => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const history = useHistory();
  const { id } = useParams();
  const local = JSON.parse(localStorage.getItem("user"));

  const [value, setValue] = React.useState(0);
  const [ratingChange, setRatingChange] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [coverImages, setCoverImages] = useState([]);

  const { data: businessDetails, isSuccess: businessSuccess } =
    useBusinessQuery.Get_details(id);

  const { data: getRating, isSuccess: getSuccess } =
    useBusinessQuery.Get_rating(id);

  const { mutateAsync: updateRating, isSuccess: sucesssRating } =
    useBusinessQuery.Update_rating();

  const { data: bookmarkList } = useBusinessQuery.Get_bookmark_list();

  const { mutateAsync: addBookmark } = useBusinessQuery.Add_bookmark();

  const { mutateAsync: addViews } = useBusinessQuery.Add_views();

  const { mutateAsync: addCalls } = useBusinessQuery.Add_calls();

  const slide = businessDetails?.data?.catalogue_images
    ?.filter((item) => Boolean(item.catalogue_image))
    ?.map((data) => ({
      src: data?.catalogue_image,
      key: data?.id,
      id: data?.id,
      width: "100%",
      height: "100%",
    }));

  const scrollTo = () =>
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

  const handleViews = async () => {
    try {
      await addViews({
        business_ids: [parseInt(id)],
      });
    } catch (error) { }
  };

  const handleAddFavourite = async (e, id) => {
    if (local?.id) {
      try {
        await addBookmark(id);
      } catch (error) { }
    } else {
      return;
    }
  };

  const handleRating = async () => {
    try {
      await updateRating({
        score: value,
        id,
      });
    } catch (error) { }
  };

  useEffect(() => {
    if (businessSuccess && businessDetails) {
      setCoverImages(
        businessDetails.data.cover_images.map((item) => ({
          cover_image: item.cover_image,
        }))
      );
    }
  }, [businessSuccess, businessDetails]);

  useEffect(() => {
    scrollTo();
    handleViews();
  }, []);

  useEffect(() => {
    if (sucesssRating) {
      setRatingChange(false);
    }
  }, [sucesssRating]);

  useEffect(() => {
    if (getSuccess) {
      setValue(getRating?.data?.score);
      if (getRating?.data?.score) {
        setRatingChange(false);
      }
    }
  }, [getSuccess]);

  const handleCalls = async () => {
    try {
      await addCalls({
        business_id: parseInt(id),
      });
    } catch (error) { }
  };

  const handleShare = async () => {
    try {
      await navigator.share({
        url: `https://backend.masterclick.in/api/v1/business/${id}/preview/`,
      });
    } catch (error) { }
  };

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            zIndex: "1000",
            background: "#2182B1",
            justifyContent: "space-between",
            height: "50px",
            padding: "0px 15px",
          }}
        >
          <ArrowBackIcon
            onClick={() => history.goBack()}
            style={{
              color: "white",
            }}
          />
          <Button
            onClick={() => history.push("/")}
            style={{
              border: "1px solid #FFFFFF",
              borderRadius: "8px",
              color: "#fff",
              textTransform: "none",
              fontSize: "12px",
              fontWeight: "600",
            }}
            startIcon={<HomeIcon />}
          >
            Go to Home
          </Button>
        </div>

        <Box>
          {/* <Carousel
            style={{ cursor: "pointer" }}
            showIndicators={true}
            showThumbs={false}
            showStatus={false}
            autoPlay={
              coverImages.length > 1 ? true : false
            }
            infiniteLoop={true}
          >
            {coverImages.length > 0 &&
              coverImages?.map((item, i) => (
                <Box
                  key={i}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={item?.cover_image ? item?.cover_image : shopimage}
                    style={{
                      width: "100%",

                      height: "270px",
                      objectFit: "cover",
                    }}
                    loading="lazy"
                    alt="hero"
                  />
                </Box>
              ))}
            {businessDetails?.data?.cover_images?.length == 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={shopimage}
                  style={{
                    width: "100%",

                    height: "270px",
                    objectFit: "cover",
                  }}
                  loading="lazy"
                  alt="hero"
                />
              </Box>
            ) : null}
          </Carousel> */}
          <HeroCarousel data={businessDetails?.data?.cover_images} />
        </Box>
      </div>
      <div className="product-content" style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "5px",
          }}
        >
          <div>
            <h3 style={{ paddingBottom: 0, margin: 0 }}>
              {businessDetails && businessDetails?.data?.name}
            </h3>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: ".5rem",
              }}
            >
              <img src={categoryicon} width="16px" height="16px" alt="" />
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  fontSize: "16x",
                  fontWeight: "500",
                }}
              >
                {businessDetails && businessDetails?.data?.sub_category}
              </p>
            </div>
          </div>
          <Box sx={{ display: "flex", alignItems: "center", gap: "13px" }}>
            <Box
              sx={{
                width: "32px",
                height: "32px",
                border: "1px solid rgba(238, 238, 238, 1)",
                borderRadius: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Checkbox
                {...label}
                checked={
                  bookmarkList?.data?.results?.some((value) => value.id === id)
                    ? true
                    : false
                }
                checkedIcon={
                  <Favorite
                    style={{
                      color: "#ff0000",
                      width: "16",
                      height: "14.2px",
                    }}
                  />
                }
                icon={
                  <FavoriteBorder
                    style={{
                      color: "#2b2b2b",
                      width: "16",
                      height: "14.2px",
                    }}
                  />
                }
                onChange={(e) => handleAddFavourite(e, id)}
                sx={{
                  width: "32px",
                  height: "32px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            </Box>
            <div onClick={handleShare}>
              <ShareIcon />
            </div>
          </Box>
        </div>
        <hr />
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            margin: "15px 0px",
          }}
        >
          <img
            src={locations}
            style={{ color: "rgba(33, 130, 177, 1)" }}
            width="16px"
            height="16px"
            alt=""
          />
          <Typography style={{ fontSize: "12px", fontWeight: "500" }}>
            {businessDetails?.data?.post_office &&
              businessDetails?.data?.post_office + ", "}
            {businessDetails?.data?.district_name &&
              businessDetails?.data?.district_name + ", "}
            {businessDetails?.data?.state_name &&
              businessDetails?.data?.state_name + ", "}
            {businessDetails?.data?.pin_code && businessDetails?.data?.pin_code}
          </Typography>
        </div>
        <div>
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: "600",
              marginTop: "17px",
            }}
          >
            About Us
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "23px",
              marginTop: "5px",
              marginBottom: "12px",
              wordBreak: "break-word !important",
            }}
          >
            {businessDetails && businessDetails?.data?.about_us}
          </Typography>
          <hr />
        </div>
        {businessDetails?.data?.services?.length > 0 ? (
          <Box style={{ marginTop: "20px" }}>
            <Typography sx={{ fontSize: "15px", fontWeight: "600" }}>
              Services
            </Typography>
            <Grid container columnSpacing={1} sx={{ marginBottom: "10px" }}>
              {businessDetails?.data?.services?.map((item) => (
                <Grid item xs={6} key={item.id}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <Box
                      sx={{
                        background: "#14A800",
                        width: "8px",
                        height: "8px",
                        paddingRight: "7px",
                        borderRadius: "20px",
                        marginTop: "8px",
                      }}
                    ></Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "400",
                        wordBreak: "break-word",
                        marginLeft: "8px",
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <hr />
          </Box>
        ) : null}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
            margin: "20px 0px",
            flexWrap: "wrap",
          }}
        >
          {businessDetails?.data?.mobile_number &&
            businessDetails?.data?.mobile_number.length > 0 ? (
            <div className="social-media" onClick={handleCalls}>
              <a
                style={{
                  color: "white",
                  textDecoration: "none",
                  display: "flex",
                }}
                href={`tel:+91-${businessDetails?.data?.mobile_number}`}
              >
                <img
                  src={phonecall}
                  alt="not found"
                  width="35px"
                  height="35px"
                />
              </a>
            </div>
          ) : null}
          {businessDetails?.data?.google_location_link &&
            businessDetails?.data?.google_location_link?.length > 0 ? (
            <div className="social-media">
              <a
                style={{
                  color: "white",
                  textDecoration: "none",
                  display: "flex",
                }}
                href={businessDetails?.data?.google_location_link}
              >
                <img src={google} width="35px" height="35px" alt="not found" />
              </a>
            </div>
          ) : null}
          {businessDetails?.data?.whatsapp_number &&
            businessDetails?.data?.whatsapp_number.length > 0 ? (
            <div className="social-media">
              <a
                style={{
                  color: "white",
                  textDecoration: "none",
                  display: "flex",
                }}
                href={`https://wa.me/91${businessDetails?.data?.whatsapp_number}?text=Hello`}
              >
                <img
                  src={whatsapp}
                  alt="not found"
                  width="35px"
                  height="35px"
                />
              </a>
            </div>
          ) : null}
          {businessDetails?.data?.facebook ? (
            <div className="social-media">
              <a
                style={{
                  color: "white",
                  textDecoration: "none",
                  display: "flex",
                }}
                href={businessDetails?.data?.facebook}
              >
                <img
                  src={facebook}
                  alt="not found"
                  width="35px"
                  height="35px"
                />
              </a>
            </div>
          ) : null}
          {businessDetails?.data?.instagram ? (
            <div className="social-media">
              <a
                style={{
                  color: "white",
                  textDecoration: "none",
                  display: "flex",
                }}
                href={businessDetails?.data?.instagram}
              >
                <img
                  src={instagram}
                  alt="not found"
                  width="35px"
                  height="35px"
                />
              </a>
            </div>
          ) : null}

          {businessDetails?.data?.youtube ? (
            <div className="social-media">
              <a
                style={{
                  color: "white",
                  textDecoration: "none",
                  display: "flex",
                }}
                href={businessDetails?.data?.youtube}
              >
                <img src={Youtube} alt="not found" width="35px" height="35px" />
              </a>
            </div>
          ) : null}

          {businessDetails?.data?.website ? (
            <div className="social-media">
              <a
                style={{
                  color: "white",
                  textDecoration: "none",
                  display: "flex",
                }}
                href={businessDetails?.data?.website}
              >
                <img src={web} width="35px" height="35px" alt="not found" />
              </a>
            </div>
          ) : null}
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              height: "80px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p style={{ fontWeight: "500", margin: 0, fontSize: "16px" }}>
              Your Review
            </p>
            <Rating
              name="simple-controlled"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              size="small"
              readOnly={!ratingChange}
            />

            {ratingChange ? (
              <Button
                variant="contained"
                style={{
                  backgroundColor: theme.palette.secondary.main,
                  marginTop: "1rem",
                  textTransform: "none",
                }}
                className="pro-btn"
                onClick={handleRating}
              >
                {"Submit"}
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{
                  backgroundColor: theme.palette.secondary.main,
                  marginTop: "1rem",
                  textTransform: "none",
                }}
                className="pro-btn"
                onClick={() => setRatingChange(true)}
              >
                Edit
              </Button>
            )}
          </div>
          <div style={{ height: "80px" }}>
            <p style={{ fontWeight: "500", margin: 0, fontSize: "15px" }}>
              {businessDetails?.data?.average_rating &&
                businessDetails?.data?.average_rating.toFixed(1)}{" "}
              {businessDetails?.data?.no_of_ratings
                ? `(${businessDetails?.data?.no_of_ratings} reviews)`
                : "0 reviews"}
            </p>
            <Rating
              name="read-only"
              value={
                businessDetails?.data?.average_rating
                  ? businessDetails?.data?.average_rating
                  : 0
              }
              size="small"
              precision={0.1}
              readOnly={true}
            />
          </div>
        </div>
      </div>

      {businessDetails?.data?.catalogue_images?.length > 0 ? (
        <div
          style={{
            width: "100%",
            marginBottom: "40px",
          }}
        >
          <h3 style={{ margin: 0, padding: "0px 15px" }}>Catalogues</h3>
          <Card sx={{ boxShadow: "3px 1px 7px #00000061", marginTop: "10px" }}>
            <CardContent sx={{ paddingTop: "0px", paddingBottom: "10px" }}>
              <Grid
                container
                spacing={3}
                sx={{ marginTop: "7px", justifyContent: "center" }}
                alignItems={"center"}
              >
                {businessDetails?.data?.catalogue_images?.filter((data) => Boolean(data.catalogue_image))?.map((item,index) => (
                  <Grid
                    item
                    xs={6}
                    key={item.id}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div onClick={() => {
                      setSelectedIndex(index)
                      setOpen(true)
                    }} className="img-parent">
                      <Typography
                        className="typo-name"
                      >
                        {item.name}
                      </Typography>
                      <img
                        src={item.catalogue_image}
                        width="100%%"
                        height="100%"
                        alt=""
                      />{" "}
                      <div className="img-child"></div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
          <Lightbox
            open={open}
            close={() => setOpen(false)}
            slides={slide?.length > 0 ? slide : []}
            index={selectedIndex}
            render={{
              buttonPrev: slide.length <= 1 ? () => null : undefined,
              buttonNext: slide.length <= 1 ? () => null : undefined,
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export default memo(ProductDetails);
