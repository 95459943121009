import React, { useContext, useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useStyles from "../../SharedClass/sharedClass";
import theme from "../../utils/theme";
import "../../components/header/Header.css";
import ProductCard from "../../components/ProductCard";
import useBusinessQuery from "../../store/Bussiness/useBusinessQuery";
import { variableContext } from "../../context/VariableContex";
import BookmarkNotFound from "../myListing/bookmark/bookmarkNotFound/BookmarkNotFound";
import locations from "../../assets/locations.svg";
import useCommonQuery from "../../store/common/useCommonQuery";

function CategoryDetails() {
  const { locationData } = useContext(variableContext);

  const history = useHistory();

  const inputRef = useRef();

  const params = useParams();

  const classes = useStyles();

  const [value, setValue] = useState("");
  const [search, setSearch] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState("");

  const { data: categoryList } = useCommonQuery.Get_category({
    search: "",
    limit: 500,
    offset: 0
  });

  const { data: subcategoryList } = useCommonQuery.Get_subcategory({
    id: "",
    search: "",
    limit: 500,
    offset: 0,
  });

  const handleChange = (e) => {
    setValue(e.target.value);
    if (e.target.value == "location") {
      history.push("/location-search");
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const query = {
    latitude: locationData?.latitude,
    longitude: locationData?.longitude,
    radius: locationData?.radius || null,
    category: params?.id ? params?.id : "",
    subCategory: params.name ? params?.name : "",
    name: search,
    limit: 500,
    offset: 0,
  };

  const {
    data: list,
    refetch,
    isLoading,
  } = useBusinessQuery.Get_bussiness_list(query);
  
  useEffect(() => {
    if (categoryList && params?.id) {
      setCategoryName(
        categoryList?.data?.results?.find((item) => item.id == params?.id)?.name
      );
    } else {
      if (!params?.id && subcategoryList) {
        setCategoryName(
          subcategoryList?.data?.results?.find(
            (item) => item.id == params?.name
          )?.category_name
        );
      }
    }
  }, [subcategoryList, categoryList]);

 

  useEffect(() => {
    refetch();
  }, [currentPage, search, locationData]);

  return (
    <div ref={inputRef} style={{ height: "100vh", overflowY: "scroll" }}>
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
          color: "black",
          padding: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: "1rem",
            marginBottom: "3px",
          }}
        >
          <ArrowBackIcon
            onClick={() => history.goBack()}
            style={{
              color: "black",
            }}
          />
          <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
            {categoryName} {list && list?.data?.length && list?.data?.length !== 0 ? `(${list?.data?.length})` : null}
          </Typography>
        </Box>

        <div className="dropdown-search-body">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                className={classes.searchBar}
                placeholder="Search"
                value={search}
                onChange={handleSearch}
              />
            </Grid>
            <Grid item xs={12}>
              <Box className={classes.categoryLocSearch}>
                <img src={locations} alt="" />
                <FormControl sx={{ flex: 1 }}>
                  <Select
                    value={value}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none !important",
                      },
                      "& .MuiSelect-select": {
                        color: "rgba(33, 130, 177, 1) !important",
                        fontSize: "16px !important",
                        fontWeight: "600 !important",
                      },
                    }}
                  >
                    <MenuItem value="">{locationData?.locationName}</MenuItem>
                    <MenuItem value="location">select location</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
      <div style={{ margin: "10px", paddingBottom: "3rem" }}>
        {list && list?.data?.map((card, i) => <ProductCard item={card} key={i} />)}
      </div>
      {list?.data?.length === 0 && <BookmarkNotFound title={"No items"} />}
      {isLoading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 5 }}><CircularProgress /></Box> : null}
    </div>
  );
}

export default React.memo(CategoryDetails);
