import React, { useContext, useState } from "react";
import "./Header.css";
import theme from "../../utils/theme";
import search1 from "../../assets/search1.svg";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SliderModal from "./SliderModal";
import { variableContext } from "../../context/VariableContex";
import locations from "../../assets/locations.svg";
import category2 from "../../assets/category2.svg";
import blue from "../../assets/blue.png";
import ShareIcon from "@mui/icons-material/Share";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";

const Header = () => {
  const history = useHistory();
  const { locationData, setLocationFilter } = useContext(variableContext);

  const [value, setValue] = useState("");

  const handleChange = (e) => {
    setValue(e.target.value);
    if (e.target.value === "select location") {
      history.push("/location-search");
    }
  };
  const handleShare = async () => {
    try {
      await navigator.share({
        url: window.location.href,
      });
    } catch (error) {}
  };

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, onError);
    }
  }

  function onError(error) {
    if (error) {
      // setOpenLocation(true);
    }
  }

  function showPosition(position) {
    setLocationFilter('latitude', position.coords.latitude);
    setLocationFilter('longitude', position.coords.longitude);
  }

  return (
    <div
      className="header"
      style={{ backgroundColor: theme.palette.primary.main }}
    >
      <div className="container" style={{ padding: "1rem 1rem 0rem 1rem" }}>
        <div className="logo-icon-body">
           {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
          <img
            src={blue}
            alt="NO Image"
            width="150px"
            height={"100%"}
            style={{ marginBottom: ".5rem" }}
          />
          <div style={{ marginRight: "6px" }} onClick={handleShare}>
            <ShareIcon sx={{ color: "white" }} />
          </div>
        </div>

        <div className="dropdown-search-body">
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <div className="loc-dropdown" style={{ paddingLeft: "6px" }}>
                <img src={locations} alt="" />
                <FormControl>
                  <Select
                    value={value}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none !important",
                      },
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "rgba(33, 130, 177, 1)",
                      "& .MuiSvgIcon-root": {
                        paddingBottom: "2px",
                      },
                      "& .MuiSelect-select": {
                        paddingLeft: "6px",
                      },
                    }}
                  >
                    <MenuItem value="">{locationData?.locationName}</MenuItem>
                    <MenuItem value="select location">select location</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  sx={{ minWidth: "0px", padding: "0px" }}
                  onClick={() => getLocation()}
                >
                  <GpsFixedIcon
                    sx={{
                      color: "rgba(33, 130, 177, 1)",
                      fontSize: "20px",
                      marginRight: "8px",
                    }}
                  />
                </Button>
              </div>
            </Grid>

            <Grid item xs={5}>
              <div className="radius-dropdown">
                <img src={category2} alt="" />

                <Button
                  endIcon={<ArrowDropDownIcon />}
                  style={{
                    textTransform: "none",
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "rgba(33, 130, 177, 1)",
                  }}
                  sx={{
                    "& .MuiButton-endIcon": {
                      marginLeft: "0px",
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: "22px",
                    },
                  }}
                  onClick={() => history.push("/subcategory-search")}
                >
                  Category
                </Button>
              </div>
            </Grid>
            <Grid item xs={2} sx={{ display: "flex", justifyContent: "end" }}>
              <Box className="search-box">
                <img
                  onClick={() => {
                    history.push("/searchscreen");
                  }}
                  src={search1}
                  alt=""
                />
              </Box>
            </Grid>
          </Grid>
        </div>
        <SliderModal />
      </div>
    </div>
  );
};

export default Header;
