import React, { useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import "./Location.css";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { variableContext } from "../../context/VariableContex";

const LocationSearch = () => {
  const history = useHistory();

  const {  setLocationFilter } = useContext(variableContext);

  const Api_Key = "AIzaSyDMC5uUfcSScgVY9AGqsGQwYm8P6_LmyYg";

  const handleLocation = (place) => {
    let lat = place.geometry.location.lat();
    let long = place.geometry.location.lng();
    setLocationFilter('latitude', lat);
    setLocationFilter('longitude', long);
    setLocationFilter('locationName', place.name);
    history.goBack();
  };

  return (
    <div>
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
          color: "black",
          display: "flex",
          width: "100%",
          alignItems: "center",
         
          flexDirection: "column",
          padding: "1rem",
          position: "fixed",
          top: 0,
        }}
       
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            width: "100%",
          }}
        >
          <CloseIcon
            onClick={() => history.push("/")}
            style={{
              color: "black",
            }}
          />
          <p style={{ color: "black" }}>Location</p>
        </div>
        <ReactGoogleAutocomplete
          apiKey={Api_Key}
          options={{
            types: [
              "neighborhood",
              "locality",
              "sublocality",
              "postal_code",
              "point_of_interest",
              // "ALL",
            ],
            componentRestrictions: { country: "in" },
            fields: [
              "address_components",
              "geometry",
              "icon",
              "name",
              "place_id",
              "formatted_address",
              // "ALL",
            ],
          }}
          name="location"
          onPlaceSelected={(place) => handleLocation(place)}
          style={{
            width: "100%",
            height: "50px",
            border: "1px solid rgba(111, 111, 111, 1)",
            borderRadius: "10px",
            paddingLeft:"15px"
          }}
        />
      </div>
    </div>
  );
};

export default LocationSearch;
