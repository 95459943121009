import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useHistory, useParams } from "react-router-dom";
import searchicon from "../../assets/search.png";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import useStyles from "../../SharedClass/sharedClass";
import useBusinessQuery from "../../store/Bussiness/useBusinessQuery";
import ProductCard from "../../components/ProductCard";
import { variableContext } from "../../context/VariableContex";
import locations from "../../assets/locations.svg";
import category2 from "../../assets/category2.svg";
import { Virtuoso } from 'react-virtuoso'

function SearchScreen() {
  const history = useHistory();
  const classes = useStyles();
  const { locationData } = useContext(variableContext);

  const [value, setValue] = useState("");
  const [search, setSearch] = useState("");

  const handleChange = (e) => {
    setValue(e.target.value);
    if (e.target.value === "select location") {
      history.push("/location-search");
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const query = {
    name: search,
    latitude: locationData?.latitude,
    longitude: locationData?.longitude,
    radius: locationData?.radius || null,
    category: "",
    subCategory: "",
    limit: 2000,
    offset: 0
  };

  const { data: list, isLoading, refetch } = useBusinessQuery.Get_bussiness_list(query);

  useEffect(() => {
    refetch();
    
  }, [search, locationData]);

  return (
    <div>
      <div
        style={{
          backgroundColor: "#fff",
          boxShadow: "-2px 0px 5px rgba(22, 107, 142, 0.25)",
          color: "black",
          padding: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: "1rem",
            marginBottom: "20px",
          }}
        >
          <CloseIcon
            onClick={() => history.push("/")}
            style={{
              color: "black",
            }}
          />
          <Typography style={{ fontSize: "16px", fontWeight: "600" }}>
            Search
          </Typography>
        </Box>
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          className={classes.searchBar}
          placeholder="Search"
          value={search}
          onChange={handleSearch}
        />
        <div className="dropdown-search-body">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box className={classes.categoryLocSearch}>
                <img src={locations} alt="" />
                <FormControl
                  sx={{
                    flex: 1,
                  }}
                >
                  <Select
                    value={value}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none !important",
                      },

                      color: "#2182B1 !important",
                    }}
                  >
                    <MenuItem value="">{locationData?.locationName}</MenuItem>
                    <MenuItem value="select location">select location</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className={classes.categoryLocSearch}>
                <img src={category2} alt="" />

                <Button
                  sx={{
                    flex: 1,
                    textTransform: "none",
                    paddingLeft: "8px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  endIcon={<ArrowDropDownIcon />}
                  onClick={() => history.push("/subcategory-search")}
                >
                  Category
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
       
      </div>
      <div style={{ margin: "10px", paddingBottom: "2rem" }}>
        {list &&
          <Virtuoso
            style={{ height: 600 }}
            data={list?.data}
            itemContent={(_, item) => (
              <ProductCard item={item} key={item.id} />
            )}
          />
        }
      </div>{" "}
      {list?.data?.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          <img src={searchicon} width="120px" height="90px" alt="" />
          <Typography
            sx={{ fontSize: "24px", fontWeight: "500", marginTop: "20px" }}
          >
            No Results Found
          </Typography>
        </Box>
      ) : isLoading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 5 }}><CircularProgress /></Box> : ''}
      
    </div>
  );
}

export default SearchScreen;
