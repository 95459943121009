import { createContext, useState } from "react";

export const variableContext = createContext();

const Variable = ({ children }) => {
  const [mobile, setMobile] = useState("");

  const [locationData, setLocationData] = useState(() => {
    const savedFilters = JSON.parse(sessionStorage.getItem('locationData')) || {};

    return { radius: 50, ...savedFilters };
  });

  const setLocationFilter = (field, data) => {
    setLocationData((prevData) => {
      // Update the specified field with the new data
      const updatedFilters = { ...prevData, [field]: data };

      // Save updated filters to sessionStorage
      sessionStorage.setItem('locationData', JSON.stringify(updatedFilters));

      return updatedFilters;
    });
  };

  return (
    <variableContext.Provider
      value={{
        mobile,
        setMobile,
        locationData,
        setLocationFilter
      }}
    >
      {children}
    </variableContext.Provider>
  );
};

export default Variable;
